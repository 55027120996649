



import { loadingController } from '@/components/global-loading/global-loading-controller'
import { Observer } from 'mobx-vue'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { connectSocialController } from '../viewmodels/connect-socical-controller'
@Observer
@Component({
  components: {},
})
export default class LoginRedirect extends Vue {
  connectSocialController = connectSocialController
  loadingController = loadingController

  @Watch('$route.query.access_token', { immediate: true }) onAccessTokenChange(val) {
    if (val) {
      const provider = this.$router.currentRoute.params.provider
      connectSocialController.handleConnectSocial(provider, val)
    }
  }
}
